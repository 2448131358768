@keyframes slide-in {
        0%{
            transform: translateY(100%);
        }
        100%{
            transform: translateY(0%);
        }
    }
.notification-container{
    position: fixed;
    bottom: 2vh;
    right: 2vw;
    z-index: 9999;
    background-color: transparent;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}
.notification-header, .notification-body{
     background-color: #2f77bf; 
    font-size: 2vh;
    font-weight: 300;
    border-radius: 1rem;
    text-align: center;
    
    animation: slide-in 0.3s ease
        
}
