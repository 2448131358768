.dialogform {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: space-around;
    background-color: #1b1e21;
    width: 40vw;
    height: 60vh;
    padding-bottom: 1rem;
    border-radius: 1rem;
    border-style: solid;
    margin: 0;
    border-color: rgb(17, 18, 18);
  }
  .dialogformGroup{
      text-align: left;
      font-size: 1.5rem;
      width: 80%;
      height: 3rem;
      border-radius: 0.5rem;
      border: none;
      outline: none;
      padding: 1rem;
  }
  .dialogformInput:focus, .dialogformInput:active, .dialogformInput{
    color: white;
    background-color: #24292d;
    border-radius: 1rem;
    border-style: solid;
    border-color:rgb(17, 18, 18);
    padding: 0.5rem;
  }
  
  .dialogformError{
      color: white;
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
      text-align: left;
      width: 80%;
      height: 1rem;
  }
  @media only screen and (min-width: 2160px){
  
      .dialogformGroup{
          font-size: 2.5rem;
      }
      .dialogformError{
          font-size: 1.5rem;
      }
      .dialogformInput{
          height: 4rem;
          font-size: 2rem;
      }
  }
  @media only screen and (max-width: 800px){
    .dialogform {
      width: 80vw;
    }
  }