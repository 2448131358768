.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #171a1c;
  height: 35vh;
  width: 40vw;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
  border-style: solid;
  margin: 3rem;
  border-color: rgb(17, 18, 18);
}
.formGroup{
    text-align: left;
    font-size: 1rem;
    width: 80%;
    height: 6rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    padding: 1rem;
}
.formInput:focus, .formInput:active, .formInput{
    color: white;
  background-color: #24292d;
  height: 2.5rem;
  border-radius: 1rem;
  border-style: solid;
  border-color:rgb(17, 18, 18)
}

.formError{
    color: white;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 80%;
    height: 1rem;
}
@media only screen and (min-width: 2160px){

    .formGroup{
        font-size: 2.5rem;
    }
    .formError{
        font-size: 1.5rem;
    }
    .formInput{
        height: 4rem;
        font-size: 2rem;
    }
}
@media only screen and (max-width: 800px){
  .form {
    width: 80vw;
  }
}